import { Company, EmploymentType } from "./model";
import { toDateString } from "./util";

export const MINIMUM_HOURLY_RATE = [
    ["2025-01-01", 12.82],
    ["2024-01-01", 12.41],
    ["2022-10-01", 12.0],
    ["2022-07-01", 10.45],
    ["2022-01-01", 9.82],
    ["2021-07-01", 9.6],
    ["2021-01-01", 9.5],
    ["2020-01-01", 9.35],
    ["2019-01-01", 9.19],
    ["2018-01-01", 8.84],
    ["2017-01-01", 8.84],
    ["2015-01-01", 8.5],
] as [string, number][];

export function getMinimumHourlyRate(date: string | Date = new Date()): number {
    if (date instanceof Date) {
        date = toDateString(date);
    }

    const period = MINIMUM_HOURLY_RATE.find(([start]) => date >= start);
    return period ? period[1] : 0;
}

/** @deprecated use getAncillaryCostFactor instead */
export function legacyGetAncillaryCostFactor(type: EmploymentType) {
    const ANCILLARY_COST_FACTORS: { [type: number]: [number, number] } = {
        [EmploymentType.ShortTerm]: [0, 0],
        [EmploymentType.Independent]: [0, 0],
        [EmploymentType.Marginal]: [0.348, 0],
        [EmploymentType.Regular]: [0.19825, 0.19825],
        [EmploymentType.Trainee]: [0.19825, 0.19825],
        [EmploymentType.MidiJob]: [0.19825, 0.19825],
        [EmploymentType.WorkingStudent]: [0.0935, 0.0935],
        [EmploymentType.DualStudent]: [0.19825, 0.19825],
        [EmploymentType.Intern]: [0, 0],
    };
    return ANCILLARY_COST_FACTORS[type] || [0, 0];
}

export function getMaxPay(
    type: EmploymentType,
    date: string | Date = new Date(),
    company: Company
): number | undefined {
    const MAXIMUM_MONTHLY_PAY: Record<string, Record<string, [string, number][]>> = {
        DE: {
            [EmploymentType.Marginal]: [
                ["2025-01-01", 556],
                ["2024-01-01", 538],
                ["2022-10-01", 520],
                ["2013-01-01", 450],
            ],
            [EmploymentType.MidiJob]: [
                ["2023-01-01", 2000],
                ["2022-10-01", 1600],
                ["2019-07-01", 1300],
                ["2013-01-01", 850],
            ],
        },
        AT: {
            [EmploymentType.Marginal]: [
                ["2025-01-01", 551.1],
                ["2024-01-01", 518.44],
                ["2023-01-01", 500.91],
                ["2022-01-01", 485.85],
                ["2021-01-01", 475.86],
                ["2020-01-01", 460.66],
                ["2019-01-01", 446.81],
                ["2018-01-01", 438.05],
                ["2017-01-01", 425.07],
            ],
        },
    };

    if (date instanceof Date) {
        date = toDateString(date);
    }

    const timeline = MAXIMUM_MONTHLY_PAY[company.country][type] as [string, number][] | undefined;

    const period = timeline && timeline.find(([start]) => date >= start);
    return period ? period[1] : undefined;
}
