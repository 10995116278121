import {
    TimeEntry,
    TimeEntryType,
    Employee,
    Company,
    CompanySettings,
    TimeSettings,
    NominalHoursMode,
    BreakMode,
    AbsenceMode,
    BreakTiming,
    BonusType,
    Bonus,
    BonusTypeMode,
    BonusLegalType,
    DailyResults,
    VacationIncrement,
    MealsMode,
    CostCenter,
    Balances,
    Position,
    Department,
    Venue,
    VacationBalance,
    TimeBalance,
    BonusesBalance,
    BonusCompType,
    AutoBreak,
} from "./model";
import { isHoliday, getHolidaysInRange } from "./holidays";
import {
    getRange,
    dateAdd,
    dateSub,
    parseDateString,
    parseTime,
    parseTimes,
    toDateString,
    getWeekNumber,
} from "./util";
import { getHourlyRate, getAncillaryCostFactor, getPayrollPeriod } from "./payroll";
import { calcVacationEntitlement } from "./vacation";
import {
    Days,
    Euros,
    Factor,
    Hours,
    Meals,
    Rate,
    multiplyWithRate,
    multiplyWithFactor,
    Percent,
    add,
    Milliseconds,
    multiplyWithPercent,
    min,
    max,
    subtract,
    millisecondsToHours,
    round,
    DateString,
    Minutes,
    sum,
} from "@pentacode/openapi";
import { PentacodeAPIModels } from "./rest/api";
import { getPayrollIssues, getShiftIssues } from "./issues";

export type Interval = [Date, Date];

export const second = 1000;
export const minute = 60 * second;
export const hour = 60 * minute;
export const day = 24 * hour;
export const week = 7 * day;
// export const weekFactor = 4.35;
export const nullInterval: Interval = [new Date(0), new Date(0)];

export function union(...intervals: Interval[]): Interval[] {
    const itvs = [...intervals].sort(([a], [b]) => Number(a) - Number(b));

    for (let i = 0; i < itvs.length - 1; ) {
        if (itvs[i][1] >= itvs[i + 1][1]) {
            // If the current interval ends at or after the next intervals end, remove the next interval
            itvs.splice(i + 1, 1);
        } else if (itvs[i][1] >= itvs[i + 1][0]) {
            // if the current interval ends after the next interval starts
            // delete the next interval
            // insert new interval with the start of the current interval and the end of the next interval
            itvs.splice(i, 2, [itvs[i][0], itvs[i + 1][1]]);
        } else {
            i++;
        }
    }

    return itvs;
}

// returns the intersection of intervals
export function intersect(...intervals: Interval[]): Interval {
    // latest start date
    const start = new Date(Math.max(...intervals.map((i) => Number(i[0]))));
    // earliest end date, but not earlier than start
    const end = new Date(Math.max(Number(start), Math.min(...intervals.map((i) => Number(i[1])))));
    return [start, end];
}

export function intersectAll(...all: Interval[][]): Interval[] {
    const [one, two, ...rest] = all;

    if (!one) {
        return [];
    }

    if (!two) {
        return one;
    }

    // create a.length * b.length intersections, then reduce them by union
    const intersects: Interval[] = [];
    for (const a of one) {
        for (const b of two) {
            intersects.push(intersect(a, b));
        }
    }
    const result = union(...intersects);

    // recursive call to intersect the resulting intervals with the remaining Arrays of intervals
    return intersectAll(result, ...rest);
}

export function subtractIntervals(a: Interval, b: Interval): Interval[] {
    return (
        /*
         * |---|
         *       |---|
         */
        a[0] >= b[1] || a[1] <= b[0]
            ? [a]
            : /*
               *   |---|
               * |-------|
               */
              a[0] >= b[0] && a[1] <= b[1]
              ? []
              : /*
                 * |-------|
                 *   |---|
                 */
                a[0] < b[0] && a[1] > b[1]
                ? [
                      [a[0], b[0]],
                      [b[1], a[1]],
                  ]
                : /*
                   * |-----|
                   *    |-----|
                   */
                  a[0] < b[0] && a[1] <= b[1]
                  ? [[a[0], b[0]]]
                  : /*
                     *    |-----|
                     * |-----|
                     */
                    a[0] >= b[0] && a[1] > b[1]
                    ? [[b[1], a[1]]]
                    : []
    );
}

export function subtractAllIntervals(a: Interval[], b: Interval[]): Interval[] {
    if (!b.length) {
        return a;
    }

    const result: Interval[] = [];

    for (const aInt of a) {
        const tempRes: Interval[][] = [];
        for (const bInt of b) {
            tempRes.push(subtractIntervals(aInt, bInt));
        }
        result.push(...intersectAll(...tempRes));
    }

    return union(...result);
}

// returns the cumulative overlap between a and each b interval in hours
export function overlap(a: Interval, b: Interval[]): Hours {
    let result = 0;
    for (const itv of b) {
        result += Math.max(0, Math.min(Number(a[1]), Number(itv[1])) - Math.max(Number(a[0]), Number(itv[0])));
    }
    return millisecondsToHours(result as Milliseconds);
}

export type BonusResult = {
    bonusId: number;
    type: {
        id: number;
        name: string;
        legalType?: BonusLegalType;
    };
    duration: Hours;
    percent?: Percent;
    hourlyRate?: Rate<Euros, Hours>;
    taxFree: boolean;
    wages: Euros;
    ancillaryCosts: Euros;
};

export type MealStats = {
    count: Meals;
    value: Rate<Euros, Meals>;
};

export type MealCost = MealStats & {
    costs: Euros;
};

export type MealsResult = {
    breakfast?: MealCost;
    lunch?: MealCost;
    dinner?: MealCost;
};

export type TimeResult = {
    base: {
        duration: Hours;
        hourlyRate: Rate<Euros, Hours>;
        wages: Euros;
        ancillaryCosts: Euros;
    };
    breaks: {
        duration: Hours;
        paidDuration: Hours;
        hourlyRate: Rate<Euros, Hours>;
        wages: Euros;
        ancillaryCosts: Euros;
    };
    commission?: {
        revenue: Euros;
        percent: Percent;
        wages: Euros;
        ancillaryCosts: Euros;
    };
    bonuses: BonusResult[];
    meals: MealsResult;
    days: Days;
    totalWages: Euros;
    totalCosts: Euros;
    costCenter?: {
        number: string;
        name: string;
    };
};

function calculateMealCost(meal?: MealStats): MealCost | undefined {
    const costs = (meal && multiplyWithRate(meal.count, meal.value)) || (0 as Euros);

    return meal
        ? {
              costs,
              ...meal,
          }
        : undefined;
}

export function makeTimeResult({
    baseDuration = 0 as Hours,
    breakPaid = 0 as Hours,
    breakTotal = 0 as Hours,
    revenue,
    commission,
    bonuses = [],
    days = 0 as Days,
    meals = {},
    hourlyRate = 0 as Rate<Euros, Hours>,
    ancillaryCostFactor = 0 as Factor,
    costCenter,
}: {
    baseDuration?: Hours;
    breakPaid?: Hours;
    breakTotal?: Hours;
    bonuses?: BonusResult[];
    days?: TimeResult["days"];
    meals?: {
        breakfast?: {
            count: Meals;
            value: Rate<Euros, Meals>;
        };
        lunch?: {
            count: Meals;
            value: Rate<Euros, Meals>;
        };
        dinner?: {
            count: Meals;
            value: Rate<Euros, Meals>;
        };
    };
    hourlyRate?: Rate<Euros, Hours>;
    revenue?: Euros;
    commission?: Percent;
    ancillaryCostFactor?: Factor;
    costCenter?: CostCenter;
} = {}): TimeResult {
    const baseWages = multiplyWithRate(baseDuration, hourlyRate);
    const baseAncillaryCosts = multiplyWithFactor(baseWages, ancillaryCostFactor);

    const breakWages = multiplyWithRate(breakPaid, hourlyRate);
    const breakAncillaryCosts = multiplyWithFactor(breakWages, ancillaryCostFactor);

    const bonusWages = bonuses.reduce((total: Euros, bonus) => add(total, bonus.wages), 0 as Euros);
    const bonusAncillaryCosts = bonuses.reduce((total, bonus) => add(total, bonus.ancillaryCosts), 0 as Euros);

    const commissionWages = (revenue && commission && multiplyWithPercent(revenue, commission)) || (0 as Euros);
    const commissionCost = multiplyWithFactor(commissionWages, ancillaryCostFactor);

    const mealsCost: MealsResult = {
        breakfast: calculateMealCost(meals.breakfast),
        lunch: calculateMealCost(meals.lunch),
        dinner: calculateMealCost(meals.dinner),
    };

    const totalMealsCost = sum(
        mealsCost.breakfast?.costs || (0 as Euros),
        mealsCost.lunch?.costs || (0 as Euros),
        mealsCost.dinner?.costs || (0 as Euros)
    );
    const totalWages = sum(baseWages, breakWages, bonusWages, commissionWages, commissionWages);
    const totalCosts = sum(
        totalWages,
        baseAncillaryCosts,
        breakAncillaryCosts,
        bonusAncillaryCosts,
        totalMealsCost,
        commissionCost
    );

    return {
        meals: mealsCost,
        base: {
            duration: baseDuration,
            hourlyRate,
            wages: baseWages,
            ancillaryCosts: baseAncillaryCosts,
        },
        breaks: {
            duration: breakTotal,
            paidDuration: breakPaid,
            hourlyRate,
            wages: breakWages,
            ancillaryCosts: breakAncillaryCosts,
        },
        commission: commissionWages &&
            revenue &&
            commission &&
            revenue && {
                revenue,
                percent: commission,
                wages: commissionWages,
                ancillaryCosts: commissionCost,
            },
        bonuses,
        days,
        totalWages,
        totalCosts,
        costCenter: costCenter
            ? {
                  number: costCenter.number,
                  name: costCenter.name,
              }
            : undefined,
    };
}

export function addTimeResults(a: TimeResult, b: TimeResult): TimeResult {
    const bonuses: BonusResult[] = a.bonuses.map((b) => ({ ...b }));
    for (const bonus of b.bonuses || []) {
        let existing = bonuses.find(
            (bon) =>
                bon.bonusId === bonus.bonusId && bon.hourlyRate === bonus.hourlyRate && bon.taxFree === bonus.taxFree
        );
        if (!existing) {
            existing = { ...bonus, duration: 0 as Hours, wages: 0 as Euros, ancillaryCosts: 0 as Euros };
            bonuses.push(existing);
        }
        existing.duration = add(existing.duration, bonus.duration);
        existing.wages = add(existing.wages, bonus.wages);
        existing.ancillaryCosts = add(existing.ancillaryCosts, bonus.ancillaryCosts);
    }

    return {
        base: {
            duration: add(a.base.duration, b.base.duration),
            hourlyRate: add(a.base.hourlyRate, b.base.hourlyRate),
            wages: add(a.base.wages, b.base.wages),
            ancillaryCosts: add(a.base.ancillaryCosts, b.base.ancillaryCosts),
        },
        breaks: {
            duration: add(a.breaks.duration, b.breaks.duration),
            paidDuration: add(a.breaks.paidDuration, b.breaks.paidDuration),
            hourlyRate: add(a.breaks.hourlyRate, b.breaks.hourlyRate),
            wages: add(a.breaks.wages, b.breaks.wages),
            ancillaryCosts: add(a.breaks.ancillaryCosts, b.breaks.ancillaryCosts),
        },
        bonuses,
        meals: {
            breakfast:
                a.meals.breakfast || b.meals.breakfast
                    ? {
                          count: add(a.meals.breakfast?.count, b.meals.breakfast?.count),
                          value: multiplyWithFactor(
                              add(a.meals.breakfast?.value, b.meals.breakfast?.value),
                              0.5 as Factor
                          ),
                          costs: add(a.meals.breakfast?.costs, b.meals.breakfast?.costs),
                      }
                    : undefined,
            lunch:
                a.meals.lunch || b.meals.lunch
                    ? {
                          count: add(a.meals.lunch?.count, b.meals.lunch?.count),
                          value: multiplyWithFactor(add(a.meals.lunch?.value, b.meals.lunch?.value), 2 as Factor),
                          costs: add(a.meals.lunch?.costs, b.meals.lunch?.costs),
                      }
                    : undefined,
            dinner:
                a.meals.dinner || b.meals.dinner
                    ? {
                          count: add(a.meals.dinner?.count, b.meals.dinner?.count),
                          value: multiplyWithFactor(add(a.meals.dinner?.value, b.meals.dinner?.value), 2 as Factor),
                          costs: add(a.meals.dinner?.costs, b.meals.dinner?.costs),
                      }
                    : undefined,
        },
        days: add(a.days, b.days),
        totalWages: add(a.totalWages, b.totalWages),
        totalCosts: add(a.totalCosts, b.totalCosts),
    };
}

export function getTotalResults(results: TimeResult[]): TimeResult {
    return results.reduce((total, res) => addTimeResults(total, res), makeTimeResult());
}

export function getDailyAverage(results: TimeResult[]): TimeResult {
    const total = getTotalResults(results);
    const average = makeTimeResult();
    const days = total.days;

    if (!days) {
        return total;
    }

    const dayFraction = (1 / days) as Factor;

    average.base.duration = multiplyWithFactor(total.base.duration, dayFraction);
    average.base.wages = multiplyWithFactor(total.base.wages, dayFraction);
    average.base.ancillaryCosts = multiplyWithFactor(total.base.ancillaryCosts, dayFraction);
    average.base.hourlyRate = multiplyWithFactor(total.base.hourlyRate, dayFraction);

    const bonusesByType = results.reduce((bbt, result) => {
        if (!result) {
            return bbt;
        }
        for (const bonus of result.bonuses || []) {
            if (!bbt.has(bonus.type.id)) {
                bbt.set(bonus.type.id, []);
            }
            bbt.get(bonus.type.id)!.push(bonus);
        }
        return bbt;
    }, new Map<number, BonusResult[]>());

    average.bonuses = [...bonusesByType.values()].map((bonuses) => ({
        ...bonuses[0],
        duration: multiplyWithFactor(
            bonuses.reduce((total, bonus) => add(total, bonus.duration), 0 as Hours),
            dayFraction
        ),
        wages: multiplyWithFactor(
            bonuses.reduce((total, bonus) => add(total, bonus.wages), 0 as Euros),
            dayFraction
        ),
        ancillaryCosts: multiplyWithFactor(
            bonuses.reduce((total, bonus) => add(total, bonus.ancillaryCosts), 0 as Euros),
            dayFraction
        ),
        hourlyRate: multiplyWithFactor(
            bonuses.reduce((total, bonus) => add(total, bonus.hourlyRate), 0 as Rate<Euros, Hours>),
            dayFraction
        ),
    }));

    average.meals = {
        breakfast: total.meals.breakfast && {
            count: multiplyWithFactor(total.meals.breakfast.count, dayFraction),
            value: multiplyWithFactor(total.meals.breakfast.value, dayFraction),
            costs: multiplyWithFactor(total.meals.breakfast.costs, dayFraction),
        },
        lunch: total.meals.lunch && {
            count: multiplyWithFactor(total.meals.lunch.count, dayFraction),
            value: multiplyWithFactor(total.meals.lunch.value, dayFraction),
            costs: multiplyWithFactor(total.meals.lunch.costs, dayFraction),
        },
        dinner: total.meals.dinner && {
            count: multiplyWithFactor(total.meals.dinner.count, dayFraction),
            value: multiplyWithFactor(total.meals.dinner.value, dayFraction),
            costs: multiplyWithFactor(total.meals.dinner.costs, dayFraction),
        },
    };

    return average;
}

export type TimeResultMode = "final" | "planned" | "logged" | "mixed" | "max";

function getBonusResults(company: Company, employee: Employee, entry: TimeEntry, work: Interval): BonusResult[] {
    const contract = employee.getContractForDate(entry.date);

    if (!contract) {
        return [];
    }

    const bonuses: {
        bonus: Bonus;
        type: BonusType;
        intervals: Interval[];
    }[] = [];

    for (const bonus of contract.bonuses) {
        const type = company.bonusTypes?.find((t) => t.id === bonus.typeId);

        if (!type || (bonus.positionId && entry.positionId !== bonus.positionId)) {
            continue;
        }

        const mustStartBefore = type.shiftMustStartBefore && parseTime(entry.date, type.shiftMustStartBefore);
        if (mustStartBefore && entry.start >= mustStartBefore) {
            continue;
        }

        const matchingDates =
            type.mode === BonusTypeMode.Daily
                ? [entry.date]
                : [entry.date, dateAdd(entry.date, { days: 1 })].filter((date) =>
                      type.matchesDate(date, company.country)
                  );

        let intervals: Interval[] = [];
        for (const date of matchingDates) {
            intervals.push(
                ...type.intervals.map(([start, end]) => [parseTime(date, start), parseTime(date, end)] as Interval)
            );
        }

        if (!intervals.length) {
            continue;
        }

        intervals = union(...intervals);

        bonuses.push({
            bonus,
            type,
            intervals,
        });
    }

    for (const bonus of bonuses) {
        for (const override of bonus.type.overrides) {
            const otherBonuses = bonuses.filter((b) => b.type.id === override.objectId);
            for (const otherBonus of otherBonuses) {
                otherBonus.intervals = subtractAllIntervals(otherBonus.intervals, bonus.intervals);
            }
        }
    }

    const workDuration = ((work[1].getTime() - work[0].getTime()) / hour) as Hours;

    const results: BonusResult[] = [];

    for (const { bonus, type, intervals } of bonuses) {
        let duration = overlap(work, intervals);

        if (
            (type.minDuration && duration < type.minDuration) ||
            (type.minDurationPercent && (duration / workDuration) * 100 < type.minDurationPercent)
        ) {
            duration = 0 as Hours;
        }

        let hourlyRate: Rate<Euros, Hours> | undefined;

        switch (type.compType) {
            case BonusCompType.FixedAmount:
                results.push({
                    bonusId: bonus.id,
                    type: {
                        id: type.id,
                        name: type.name,
                        legalType: type.legalType,
                    },
                    taxFree: type.taxFree,
                    ancillaryCosts: 0 as Euros,
                    duration,
                    wages: bonus.fixedAmount || type.fixedAmountDefault || (0 as Euros),
                });
                continue;
            case BonusCompType.FixedHourlyRate:
                hourlyRate = bonus.hourlyRate || type.fixedHourlyRateDefault || (0 as Rate<Euros, Hours>);
                results.push({
                    bonusId: bonus.id,
                    type: {
                        id: type.id,
                        name: type.name,
                        legalType: type.legalType,
                    },
                    taxFree: type.taxFree,
                    ancillaryCosts: 0 as Euros,
                    duration,
                    hourlyRate,
                    wages: multiplyWithRate(duration, hourlyRate),
                });
                continue;
            case BonusCompType.ModifiedHourlyRate: {
                hourlyRate = getHourlyRate(company, contract, entry.positionId);
                const percent = bonus.percent || type.defaultPercent || (0 as Percent);
                const taxFreeUpToRate = type.taxFree ? type.taxFreeUpToRate : (0 as Rate<Euros, Hours>);
                const taxFreeRate = hourlyRate && min(hourlyRate, taxFreeUpToRate);
                const taxedRate = hourlyRate && max(0 as Rate<Euros, Hours>, subtract(hourlyRate, taxFreeUpToRate));

                if (taxFreeRate || (!hourlyRate && !!taxFreeUpToRate)) {
                    const wages = multiplyWithPercent(multiplyWithRate(duration, taxFreeRate), percent);
                    results.push({
                        bonusId: bonus.id,
                        type: {
                            id: type.id,
                            name: type.name,
                            legalType: type.legalType,
                        },
                        taxFree: true,
                        hourlyRate: taxFreeRate,
                        ancillaryCosts: 0 as Euros,
                        wages,
                        duration,
                        percent,
                    });
                }

                if (taxedRate || (!hourlyRate && !taxFreeUpToRate)) {
                    const wages = multiplyWithPercent(multiplyWithRate(duration, taxedRate), percent);
                    const ancillaryCosts = multiplyWithFactor(
                        wages,
                        getAncillaryCostFactor(contract.employmentType)[0]
                    );

                    results.push({
                        bonusId: bonus.id,
                        type: {
                            id: type.id,
                            name: type.name,
                            legalType: type.legalType,
                        },
                        taxFree: false,
                        hourlyRate: taxedRate,
                        ancillaryCosts,
                        wages,
                        duration,
                        percent,
                    });
                }
                break;
            }
        }
    }

    return results;
}

export function getTimeResult(
    company: Company,
    employee: Employee,
    entry: TimeEntry,
    prevAverage: TimeResult,
    shiftsThatDay: number = 1,
    mode: TimeResultMode = "final"
): TimeResult {
    const timeSettings = company.getTimeSettings({ timeEntry: entry }) || new TimeSettings();
    const { iterativeBreaks } = company.settings;

    const mealValueBreakfast = company.settings.mealValueBreakfast;
    const mealValueLunch = company.settings.mealValueLunch;
    const mealValueDinner = company.settings.mealValueDinner;

    const contract = employee.getContractForDate(entry.date);
    const { breakMode, paidBreaksAuto, paidBreaksManual, breakTiming } = timeSettings;

    const costCenter = company.getCostCenter({ employee, timeEntry: entry, date: entry.date });

    if (!contract) {
        return makeTimeResult();
    }

    const hourlyRate = getHourlyRate(company, contract, entry);
    const commission = contract.getSalary(entry)?.commission;
    const ancillaryCostFactor = getAncillaryCostFactor(contract.employmentType)[0];

    const { absenceMode, absenceHours, fixedWorkDays, hoursPerDay } = contract;

    const year = entry.start.getFullYear();
    const month = entry.start.getMonth();
    const date = entry.start.getDate();

    if (entry.type === TimeEntryType.Work) {
        let interval: [Date | null, Date | null] | null = null;
        let breakAuto: Hours = 0 as Hours;
        let breakManual: Hours = 0 as Hours;
        const breakFinal = entry.break || (0 as Hours);

        switch (mode) {
            case "planned":
                interval = entry.planned;
                breakAuto =
                    entry.breakAuto ??
                    (breakMode === BreakMode.Manual
                        ? getStatutoryBreak(entry.durationPlanned, { iterativeBreaks })
                        : [BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                          ? entry.breakPlanned || (0 as Hours)
                          : getAutomaticBreak(entry.durationPlanned, timeSettings));
                breakManual = 0 as Hours;
                break;
            case "logged":
                interval = entry.logged;
                breakAuto =
                    entry.breakAuto ??
                    ([BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                        ? entry.breakPlanned || (0 as Hours)
                        : min(breakFinal, getAutomaticBreak(entry.durationLogged, timeSettings)));
                breakManual = subtract(breakFinal, breakAuto);
                break;
            case "final":
                interval = entry.final;
                breakAuto =
                    entry.breakAuto ??
                    ([BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                        ? entry.breakPlanned || (0 as Hours)
                        : min(breakFinal, getAutomaticBreak(entry.durationFinal, timeSettings)));
                breakManual = subtract(breakFinal, breakAuto);
                break;
            case "mixed":
                if (entry.final || entry.isPast) {
                    interval = entry.final;
                    breakAuto =
                        entry.breakAuto ??
                        ([BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                            ? entry.breakPlanned || (0 as Hours)
                            : min(breakFinal, getAutomaticBreak(entry.durationFinal, timeSettings)));
                    breakManual = subtract(breakFinal, breakAuto);
                } else {
                    interval = entry.planned;
                    breakAuto =
                        entry.breakAuto ??
                        (breakMode === BreakMode.Manual
                            ? getStatutoryBreak(entry.durationPlanned, { iterativeBreaks })
                            : getAutomaticBreak(entry.durationPlanned, timeSettings));
                    breakManual = 0 as Hours;
                }
                break;
            case "max": {
                interval =
                    entry.logged && entry.final
                        ? [
                              new Date(Math.min(entry.logged[0].getTime(), entry.final[0].getTime())),
                              new Date(Math.max(entry.logged[1].getTime(), entry.final[1].getTime())),
                          ]
                        : entry.final;
                const duration =
                    ((interval &&
                        interval[0] &&
                        interval[1] &&
                        (interval[1].getTime() - interval[0].getTime()) / hour) as Hours) || (0 as Hours);
                breakAuto =
                    entry.breakAuto ??
                    ([BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                        ? entry.breakPlanned || (0 as Hours)
                        : min(breakFinal, getAutomaticBreak(duration, timeSettings)));
                breakManual = subtract(breakFinal, breakAuto);
                break;
            }
        }

        const breakPaid = add(paidBreaksAuto ? breakAuto : (0 as Hours), paidBreaksManual ? breakManual : (0 as Hours));
        const breakTotal = add(breakAuto, breakManual);

        if (!interval || !interval[0] || !interval[1]) {
            return makeTimeResult();
        }

        let [start, end] = interval;

        // subtract break at start of end of shift, depending on setting
        if (breakTiming === BreakTiming.End) {
            end = new Date(end.getTime() - breakTotal * hour);
        } else {
            start = new Date(start.getTime() + breakTotal * hour);
        }
        const work = [start, end] as Interval;
        const baseDuration = ((end.getTime() - start.getTime()) / hour) as Hours;

        const bonuses = getBonusResults(company, employee, entry, work);

        return makeTimeResult({
            breakPaid,
            breakTotal,
            baseDuration,
            bonuses,
            meals: {
                breakfast: entry.mealsBreakfast
                    ? {
                          count: entry.mealsBreakfast,
                          value: mealValueBreakfast || (0 as Rate<Euros, Meals>),
                      }
                    : undefined,
                lunch: entry.mealsLunch
                    ? {
                          count: entry.mealsLunch,
                          value: mealValueLunch || (0 as Rate<Euros, Meals>),
                      }
                    : undefined,
                dinner: entry.mealsDinner
                    ? {
                          count: entry.mealsDinner,
                          value: mealValueDinner || (0 as Rate<Euros, Meals>),
                      }
                    : undefined,
            },
            commission,
            revenue: entry.revenue,
            hourlyRate,
            ancillaryCostFactor,
            days: (1 / shiftsThatDay) as Days,
            costCenter,
        });
    } else if (
        [
            TimeEntryType.Vacation,
            TimeEntryType.Sick,
            TimeEntryType.ChildSick,
            TimeEntryType.SickInKUG,
            TimeEntryType.CompDay,
        ].includes(entry.type)
    ) {
        let baseDuration = prevAverage.base.duration;
        let breakTotal = prevAverage.breaks.duration;
        let breakPaid = prevAverage.breaks.paidDuration;

        if (absenceMode === AbsenceMode.FixedDays && fixedWorkDays) {
            baseDuration = hoursPerDay ? hoursPerDay[(entry.start.getDay() + 6) % 7] : (0 as Hours);
            breakTotal = breakPaid = 0 as Hours;
        } else if (absenceMode === AbsenceMode.Fixed) {
            baseDuration = absenceHours;
            breakTotal = breakPaid = 0 as Hours;
        }

        // No continued pay in the first 4 weeks of employment
        const firstContract = employee.contracts.sort((a, b) => Number(a.start) - Number(b.start))[0];
        if (!firstContract || Number(new Date(year, month, date)) - Number(firstContract.start) < 4 * week) {
            const result = makeTimeResult();
            result.base.duration = baseDuration;
        }

        const bonuses: BonusResult[] = [];

        if (entry.type !== TimeEntryType.CompDay) {
            for (const bonus of contract.bonuses) {
                const bonusType = company.bonusTypes?.find((t) => t.id === bonus.typeId);
                if (bonusType?.continuedPay && bonusType?.matchesDate(entry.date, company.country)) {
                    let duration = 0 as Hours;
                    if (bonusType.mode === BonusTypeMode.Daily) {
                        const prevBonusAverage = prevAverage.bonuses?.find((b) => b.type.id === bonus.typeId);
                        duration = min(baseDuration, prevBonusAverage?.duration || (0 as Hours));
                    } else {
                        duration = baseDuration;
                    }
                    const wages = multiplyWithPercent(multiplyWithRate(duration, hourlyRate), bonus.percent);
                    bonuses.push({
                        bonusId: bonus.id,
                        type: {
                            id: bonusType.id,
                            name: bonusType.name,
                            legalType: bonusType.legalType,
                        },
                        duration,
                        taxFree: false,
                        percent: bonus.percent,
                        hourlyRate,
                        wages,
                        ancillaryCosts: multiplyWithFactor(wages, ancillaryCostFactor),
                    });
                }
            }
        }

        return makeTimeResult({
            baseDuration,
            breakPaid,
            breakTotal,
            bonuses,
            hourlyRate,
            ancillaryCostFactor,
            days: 1 as Days,
            costCenter,
        });
    } else if (entry.type === TimeEntryType.HourAdjustment) {
        return makeTimeResult({
            baseDuration: entry.hours ? entry.hours : (0 as Hours),
            hourlyRate: entry.paid ? hourlyRate : (0 as Rate<Euros, Hours>),
        });
    } else if (entry.type === TimeEntryType.VacationAdjustment) {
        const days = (entry.days || 0) as Factor;
        let baseDuration = prevAverage.base.duration;
        let breakTotal = prevAverage.breaks.duration;
        let breakPaid = prevAverage.breaks.paidDuration;

        if (absenceMode === AbsenceMode.FixedDays && fixedWorkDays) {
            baseDuration = hoursPerDay ? hoursPerDay[(entry.start.getDay() + 6) % 7] : (0 as Hours);
            breakTotal = breakPaid = 0 as Hours;
        } else if (absenceMode === AbsenceMode.Fixed) {
            baseDuration = absenceHours;
            breakTotal = breakPaid = 0 as Hours;
        }

        return makeTimeResult({
            baseDuration: multiplyWithFactor(baseDuration, days),
            hourlyRate: entry.paid ? hourlyRate : (0 as Rate<Euros, Hours>),
            breakTotal: multiplyWithFactor(breakTotal, days),
            breakPaid: multiplyWithFactor(breakPaid, days),
            days: entry.days || (0 as Days),
        });
    } else {
        return makeTimeResult();
    }
}

export function getDailyResults(
    company: Company,
    employee: Employee,
    entries: TimeEntry[],
    { from, to }: DateRange,
    previousResults: DailyResults[]
): DailyResults[] {
    // !!! This Function also potentially mutates the input TimeEntries and updates their results if not set

    const dailyResults: DailyResults[] = [];
    let date = from;

    let lastResult = previousResults[previousResults.length - 1];

    // time results are not used in this function, so we can call it before results are updated
    const issues = getShiftIssues(employee, company, entries, { from, to });

    while (date < to) {
        const dayEntries = entries.filter((e) => e.date === date);
        const workEntries = dayEntries.filter((e) => e.type === TimeEntryType.Work);
        const reset = dayEntries.find((e) => e.type === TimeEntryType.ResetLedgers);
        const allResults = [...previousResults, ...dailyResults];

        const lastDayOfPreviousMonth = dateAdd(getRange(date, "month").from, { days: -1 });
        const averagesResult = allResults.find((res) => res.date === lastDayOfPreviousMonth);

        const contract = employee.getContractForDate(date);

        const results: DailyResults["results"] = {
            work: makeTimeResult(),
            workPlanned: makeTimeResult(),
            absences: makeTimeResult(),
            adjustments: makeTimeResult(),
            total: makeTimeResult(),
        };

        let vacationDays = 0 as Days;
        let vacationAdjustments = 0 as Days;

        if (contract) {
            for (const entry of dayEntries) {
                if (!entry.result) {
                    entry.result = getTimeResult(
                        company,
                        employee,
                        entry,
                        averagesResult?.averages["13weeks"] || makeTimeResult(),
                        workEntries.filter((e) => e.startFinal && e.endFinal).length,
                        "final"
                    );
                }
                if (!entry.resultPlanned) {
                    entry.resultPlanned = getTimeResult(
                        company,
                        employee,
                        entry,
                        averagesResult?.averages["13weeks"] || makeTimeResult(),
                        workEntries.length,
                        "planned"
                    );
                }
                if (!entry.resultMaxTotalCosts) {
                    const { totalCosts } = getTimeResult(
                        company,
                        employee,
                        entry,
                        averagesResult?.averages["13weeks"] || makeTimeResult(),
                        workEntries.length,
                        "max"
                    );
                    entry.resultMaxTotalCosts = totalCosts;
                }
            }

            vacationDays = dayEntries
                .filter((e) => e.type === TimeEntryType.Vacation)
                .reduce((total, e) => add(total, e.days ?? (1 as Days)), 0 as Days);

            vacationAdjustments = dayEntries
                .filter((e) => e.type === TimeEntryType.VacationAdjustment)
                .reduce((total, e) => add(total, e.days ?? (1 as Days)), 0 as Days);

            results.work = getTotalResults(workEntries.map((e) => e.result).filter(Boolean) as TimeResult[]);
            results.workPlanned = getTotalResults(
                workEntries.map((e) => e.resultPlanned).filter(Boolean) as TimeResult[]
            );

            results.absences = getTotalResults(
                dayEntries
                    .filter((e) =>
                        [
                            TimeEntryType.Sick,
                            TimeEntryType.Vacation,
                            TimeEntryType.Sick,
                            TimeEntryType.SickInKUG,
                            TimeEntryType.ChildSick,
                            TimeEntryType.CompDay,
                        ].includes(e.type)
                    )
                    .map((e) => e.result)
                    .filter(Boolean) as TimeResult[]
            );

            results.adjustments = getTotalResults(
                dayEntries
                    .filter((e) => [TimeEntryType.HourAdjustment].includes(e.type))
                    .map((e) => e.result)
                    .filter(Boolean) as TimeResult[]
            );

            results.total = getTotalResults([results.work, results.absences, results.adjustments]);
        }

        const getTimeBalance: (planned?: boolean) => DailyResults["timeBalance"] = (planned = false) => {
            const carry =
                (planned ? lastResult?.timeBalancePlanned?.balance : lastResult?.timeBalance.balance) || (0 as Hours);
            const nominal = getNominalTime(company, employee, { from: date, to: dateAdd(date, { days: 1 }) });
            const work = planned ? results.workPlanned?.base.duration || (0 as Hours) : results.work.base.duration;
            const absences = results.absences.base.duration;
            const adjustments = results.adjustments.base.duration;
            const actual = sum(work, absences, adjustments);
            const difference = subtract(actual, nominal);
            const base = reset?.hours ?? carry;
            const balance = add(base, difference);

            return {
                reset: reset?.hours ?? undefined,
                nominal,
                work,
                adjustments,
                absences,
                actual,
                difference,
                carry,
                balance,
            };
        };

        const getVacationBalance: (timeBalance: DailyResults["timeBalance"]) => DailyResults["vacationBalance"] = (
            timeBalance
        ) => {
            const contract = employee.getContractForDate(date);
            const carry = lastResult?.vacationBalance.balance || (0 as Days);
            const base = reset?.days ?? carry;
            const vacationFactor = (
                contract?.vacationIncrement === VacationIncrement.Hourly && timeBalance.nominal
                    ? timeBalance.actual / timeBalance.nominal
                    : 1
            ) as Factor;
            let nominal = calcVacationEntitlement(
                employee,
                { from: date, to: dateAdd(date, { days: 1 }) },
                company.settings
            );
            nominal = multiplyWithFactor(nominal, vacationFactor);
            const actual = add(vacationDays, vacationAdjustments);
            const difference = subtract(nominal, actual);
            const balance = add(base, difference);

            return {
                reset: reset?.days ?? undefined,
                nominal,
                taken: vacationDays,
                adjustments: vacationAdjustments,
                actual,
                difference,
                carry,
                balance,
            };
        };

        const getBonusesBalance: () => DailyResults["bonusesBalance"] = () => {
            const taxed = results.total.bonuses
                .filter((b) => !b.taxFree)
                .reduce((total, b) => add(total, b.wages), 0 as Euros);
            const untaxed = results.total.bonuses
                .filter((b) => b.taxFree)
                .reduce((total, b) => add(total, b.wages), 0 as Euros);
            const actual = company.settings.includeTaxedBonusesInBalance ? add(taxed, untaxed) : untaxed;

            if (!contract?.enableSFNLedger) {
                return {
                    taxed,
                    untaxed,
                    actual,
                };
            }

            const nominal = getNominalBonus(employee, { from: date, to: dateAdd(date, { days: 1 }) });
            const difference = subtract(actual, nominal);

            // Bonuses balance is always reset with the beginning of a new year
            const carry = date.endsWith("01-01") ? (0 as Euros) : lastResult?.bonusesBalance.balance || (0 as Euros);
            const base = reset?.resetBonus ?? carry;
            const balance = add(base, difference);

            return {
                reset: reset?.resetBonus ?? undefined,
                nominal,
                taxed,
                untaxed,
                actual,
                difference,
                carry,
                balance,
            };
        };

        let _13weekAverage = lastResult?.averages["13weeks"] || makeTimeResult();
        // For historical reasons, we calculate a new 13 week average for each month
        // and use the same average for the whole month instead of calculating a new
        // (more accurate) value for each day
        if (dateAdd(date, { days: 1 }).endsWith("-01")) {
            // The previous daily results act as the basis for calcuating the average
            const allResults = [...previousResults, ...dailyResults];
            // We start calculating from the last work day
            const lastWorkDate = results.work.base.duration
                ? date
                : [...allResults].reverse().find((r) => r.results.work.days)?.date || date;
            const to = dateAdd(lastWorkDate, { days: 1 });
            // Averages are calculated from the last 13 weeks
            // (calculating backwards from the last work day)
            const from = dateAdd(to, { weeks: -13 });
            const averagesInput = allResults.filter((r) => r.date >= from && r.date < to);
            // Only work results are considered
            const workResults = [...averagesInput.map((e) => e.results.work), results.work];
            _13weekAverage = getDailyAverage(workResults);
        }

        const timeBalance = getTimeBalance();

        const result = new DailyResults({
            employeeId: employee.id,
            date,
            averages: {
                "13weeks": _13weekAverage,
            },
            results,
            timeBalance,
            timeBalancePlanned: getTimeBalance(true),
            vacationBalance: getVacationBalance(timeBalance),
            bonusesBalance: getBonusesBalance(),
            issues: issues.filter((i) => i.date === date),
        });

        // If this is the last date of the payroll period, check for payroll issues
        if (new Date(dateAdd(date, { days: 1 })).getDate() === company.settings.startPayrollPeriod) {
            result.issues!.push(
                ...getPayrollIssues(
                    company,
                    employee,
                    entries,
                    getPayrollPeriod(date, company.settings.startPayrollPeriod)
                )
            );
        }

        dailyResults.push(result);
        lastResult = result;

        date = dateAdd(date, { days: 1 });
    }

    return dailyResults;
}

export function getNominalTime(company: Company, emp: Employee, { from, to }: DateRange): Hours {
    const startLedgers = company.settings.startLedgers;
    const contracts = emp.contracts.filter((c) => c.start <= to && (!c.end || c.end > from));
    const defaultPosition = emp.positions[0];
    const venue =
        (defaultPosition &&
            company.venues.find((v) =>
                v.departments.some((d) => d.positions.some((p) => p.id === defaultPosition.id))
            )) ||
        company.venues[0];
    const holidays = venue?.enabledHolidays || undefined;
    const weekFactor = company.settings.weekFactor || (4.35 as Factor);

    let total = 0 as Hours;

    for (const contract of contracts) {
        const mode = contract.nominalHoursMode;
        let start = from >= contract.start ? from : contract.start;
        if (startLedgers && startLedgers > start) {
            start = startLedgers;
        }

        const end = !contract.end || contract.end > to ? to : contract.end;
        if (start > end) {
            start = end;
        }

        switch (mode) {
            case NominalHoursMode.WeekFactor:
                while (start < end) {
                    const { from, to } = getRange(start, "month");
                    const subEnd = to < end ? to : end;

                    const fraction = (dateSub(start, subEnd) / dateSub(from, to)) as Factor;

                    total = add(
                        total,
                        multiplyWithFactor(multiplyWithFactor(contract.hoursPerWeek, weekFactor), fraction)
                    );

                    start = subEnd;
                }
                break;
            case NominalHoursMode.Exact:
            case NominalHoursMode.ExactWithoutHolidays: {
                let days = dateSub(start, end);
                if (mode === NominalHoursMode.ExactWithoutHolidays) {
                    const holidaysInRange = getHolidaysInRange(start, end, { holidays, country: company.country });
                    days -= holidaysInRange.length;
                }
                const weeks = (days / 7) as Factor;
                total = add(total, multiplyWithFactor(contract.hoursPerWeek, weeks));
                break;
            }
            case NominalHoursMode.FixedDays:
            case NominalHoursMode.FixedDaysWithoutHolidays: {
                let day = start;
                while (day < end) {
                    const date = parseDateString(day)!;
                    const hours = contract.hoursPerDay?.[(date.getDay() + 6) % 7] || (0 as Hours);

                    if (
                        mode === NominalHoursMode.FixedDays ||
                        !isHoliday(date.getFullYear(), date.getMonth(), date.getDate(), {
                            holidays,
                            country: company.country,
                        })
                    ) {
                        total = add(total, hours);
                    }

                    day = dateAdd(day, { days: 1 });
                }
                break;
            }
        }
    }

    return total;
}

export function getStatutoryBreak(duration: Hours, { iterativeBreaks = false }: Partial<CompanySettings> = {}): Hours {
    return (duration <= 6 ? 0 : duration <= (iterativeBreaks ? 9.5 : 9) ? 0.5 : 0.75) as Hours;
}

export function getAutomaticBreak(duration: Hours, { breakMode, autoBreaks = [] }: Partial<TimeSettings>): Hours {
    if (breakMode === BreakMode.Manual) {
        return 0 as Hours;
    }
    return max(0 as Hours, ...autoBreaks.filter((b) => b.duration < duration).map((b) => b.amount));
}

/**
 * Mutates the given date by applying rounding to the minutes.
 *
 * If `rounding` is positive, the date is rounded up, if negative, it is rounded down.
 *
 * The date is also rounded down to the full minute.
 *
 * @param date The date to apply rounding to
 * @param rounding The rounding to apply in Minutes
 */
export function applyRounding(date: Date, rounding: Minutes) {
    if (rounding) {
        const minutes = date.getMinutes();
        // Minutes subtracted to round down
        const deltaRoundDown = minutes % rounding;
        // If rounding is positive, rounding is added to round up
        const deltaRoundUp = Math.max(0, rounding);

        // apply roundings
        date.setMinutes(minutes - deltaRoundDown + deltaRoundUp);
    }

    // Round to full minute
    date.setSeconds(0);
    date.setMilliseconds(0);
}

/**
 * Corrects to `plannedDate` if `date` was logged before `plannedDate` but rounded up beyond it
 * or if it was logged after plannedDate, but rounded down to earlier than it
 * @param date The date to be corrected
 * @param loggedTime The date `date` was logged
 * @param plannedDate The date that `date` should be corrected to
 * @returns Copy of `plannedDate` if correction is neccessary, otherwise `date`
 */
export function correctRoundedDate(date: Date, loggedTime: Date, plannedDate: Date | null) {
    if (!plannedDate) return date;

    if ((loggedTime <= plannedDate && date > plannedDate) || (loggedTime >= plannedDate && date < plannedDate)) {
        return new Date(plannedDate);
    }

    return date;
}

export function getStartFinal(
    timeEntry: TimeEntry,
    loggedTime: Date,
    useActualTimeAtShiftStart: boolean,
    shiftStartRounding: Minutes
) {
    // If logged time is earlier than planned and useActualTimeAtShiftStart setting
    // is not set, use planned time instead
    let startFinal =
        timeEntry.startPlanned && loggedTime < timeEntry.startPlanned && !useActualTimeAtShiftStart
            ? new Date(timeEntry.startPlanned)
            : new Date(loggedTime);

    applyRounding(startFinal, shiftStartRounding);

    startFinal = correctRoundedDate(startFinal, loggedTime, timeEntry.startPlanned);

    return startFinal;
}

export function getEndFinal(
    timeEntry: TimeEntry,
    loggedTime: Date,
    useActualTimeAtShiftEnd: boolean,
    shiftEndRounding: Minutes
) {
    // If logged time is later than planned and useActualTimeAtShiftEnd setting
    // is not set, use planned time instead
    let endFinal =
        timeEntry.endPlanned && loggedTime > timeEntry.endPlanned && !useActualTimeAtShiftEnd
            ? new Date(timeEntry.endPlanned)
            : new Date(loggedTime);

    applyRounding(endFinal, shiftEndRounding);

    endFinal = correctRoundedDate(endFinal, loggedTime, timeEntry.endPlanned);

    // Make sure end time is not earlier than start time
    if (timeEntry.startFinal && endFinal < timeEntry.startFinal) {
        endFinal = timeEntry.startFinal;
    }

    return endFinal;
}

export function applyAutoMeals(
    company: Company,
    timeEntry: TimeEntry,
    { breakfast, lunch, dinner }: { breakfast: boolean; lunch: boolean; dinner: boolean }
) {
    const timeSettings = company.getTimeSettings({ timeEntry });
    if (
        timeSettings.mealsMode !== MealsMode.Auto ||
        !timeEntry.final ||
        timeEntry.durationFinal < timeSettings.mealsMinDuration
    ) {
        return;
    }

    const breakfastInterval =
        timeSettings.mealEnabledBreakfast &&
        timeSettings.mealStartBreakfast &&
        timeSettings.mealEndBreakfast &&
        (parseTimes(timeEntry.date, timeSettings.mealStartBreakfast, timeSettings.mealEndBreakfast) as [Date, Date]);

    const lunchInterval =
        timeSettings.mealEnabledLunch &&
        timeSettings.mealStartLunch &&
        timeSettings.mealEndLunch &&
        (parseTimes(timeEntry.date, timeSettings.mealStartLunch, timeSettings.mealEndLunch) as [Date, Date]);

    const dinnerInterval =
        timeSettings.mealEnabledDinner &&
        timeSettings.mealStartDinner &&
        timeSettings.mealEndDinner &&
        (parseTimes(timeEntry.date, timeSettings.mealStartDinner, timeSettings.mealEndDinner) as [Date, Date]);

    if (breakfastInterval && breakfast && overlap(timeEntry.final, [breakfastInterval])) {
        timeEntry.mealsBreakfast = 1 as Meals;
    }

    if (dinnerInterval && dinner && overlap(timeEntry.final, [dinnerInterval])) {
        timeEntry.mealsDinner = 1 as Meals;
    }

    if (lunchInterval && lunch && overlap(timeEntry.final, [lunchInterval])) {
        timeEntry.mealsLunch = 1 as Meals;
    }
}

export function applyAutoBreaks(entry: TimeEntry, breakMode: BreakMode, autoBreaks: AutoBreak[]) {
    const breakAuto = [BreakMode.Planned, BreakMode.PlannedPlusManual, BreakMode.PlannedOrManual].includes(breakMode)
        ? entry.breakPlanned || (0 as Hours)
        : (getAutomaticBreak(entry.durationFinal, { autoBreaks, breakMode }) as Hours);
    const prevBreak = entry.break || (0 as Hours);
    const breakLogged = entry.breakLogged || (0 as Hours);

    if (entry.break === null || breakAuto !== entry.breakAuto) {
        const prevBreakAuto = entry.breakAuto ?? breakAuto;
        entry.breakAuto = breakAuto;

        const brk = [BreakMode.AutoOrManual, BreakMode.PlannedOrManual].includes(breakMode)
            ? max(breakAuto, breakLogged)
            : add(breakAuto, breakLogged);

        const prevBreakAutoWithManual = [BreakMode.AutoOrManual, BreakMode.PlannedOrManual].includes(breakMode)
            ? max(prevBreakAuto, breakLogged)
            : add(prevBreakAuto, breakLogged);

        // If break is null or hasn't been edited after the fact, update value
        if (entry.break === null || prevBreak - prevBreakAutoWithManual * 60 < 1) {
            entry.break = brk;
        }
    }
}

export function getNominalBonus(emp: Employee, { from, to }: DateRange): Euros {
    const contracts = emp.contracts.filter((c) => c.start <= to && (!c.end || c.end > from));

    let total: Euros = 0 as Euros;

    for (const contract of contracts) {
        let start = from >= contract.start ? from : contract.start;
        const end = !contract.end || contract.end > to ? to : contract.end;
        if (start > end) {
            start = end;
        }

        while (start < end) {
            const { from, to } = getRange(start, "month");
            const subEnd = to < end ? to : end;

            const fraction = (dateSub(start, subEnd) / dateSub(from, to)) as Factor;

            total = add(total, multiplyWithFactor(contract.sfnAdvance, fraction));

            start = subEnd;
        }
    }

    return total;
}

function roundTimeBalance<T extends Omit<TimeBalance, "employeeId" | "from" | "to">>(balance: T): T {
    return {
        ...balance,
        work: round(balance.work, 2),
        absences: round(balance.absences, 2),
        adjustments: round(balance.adjustments, 2),
        actual: round(balance.actual, 2),
        nominal: round(balance.nominal, 2),
        difference: round(balance.difference, 2),
        reset: typeof balance.reset === "number" ? round(balance.reset, 2) : undefined,
        carry: round(balance.carry ?? (0 as Hours), 2),
        balance: round(balance.balance ?? (0 as Hours), 2),
    };
}

function roundVacationBalance<T extends Omit<VacationBalance, "employeeId" | "from" | "to">>(balance: T): T {
    return {
        ...balance,
        taken: round(balance.taken, 2),
        adjustments: round(balance.adjustments, 2),
        actual: round(balance.actual, 2),
        nominal: round(balance.nominal, 2),
        difference: round(balance.difference, 2),
        reset: typeof balance.reset === "number" ? round(balance.reset, 2) : undefined,
        carry: round(balance.carry || (0 as Days), 2),
        balance: round(balance.balance || (0 as Days), 2),
    };
}

function roundBonusesBalance<T extends Omit<BonusesBalance, "employeeId" | "from" | "to">>(balance: T): T {
    return {
        ...balance,
        taxed: round(balance.taxed, 2),
        untaxed: round(balance.untaxed, 2),
        actual: round(balance.actual, 2),
        nominal: typeof balance.nominal !== "undefined" ? round(balance.nominal, 2) : undefined,
        difference: typeof balance.difference !== "undefined" ? round(balance.difference, 2) : undefined,
        reset: typeof balance.reset === "number" ? round(balance.reset, 2) : undefined,
        carry: typeof balance.carry !== "undefined" ? round(balance.carry, 2) : undefined,
        balance: typeof balance.balance !== "undefined" ? round(balance.balance, 2) : undefined,
    };
}

/** @deprecated Use `roundTimeBalance`, `roundVacationBalance`, `roundBonusesBalance` instead */
function roundBalances(balances: Omit<Balances, "employeeId" | "from" | "to">) {
    return {
        ...balances,
        time: roundTimeBalance(balances.time),
        vacation: roundVacationBalance(balances.vacation),
        bonuses: roundBonusesBalance(balances.bonuses),
    };
}

export function addTimeBalances<T extends Omit<TimeBalance, "employeeId" | "from" | "to">>(
    balanceA: T,
    balanceB: T
): T {
    return {
        ...balanceA,
        carry: add(balanceA.carry, balanceB.carry),
        actual: add(balanceA.actual, balanceB.actual),
        nominal: add(balanceA.nominal, balanceB.nominal),
        difference: add(balanceA.difference, balanceB.difference),
        work: add(balanceA.work, balanceB.work),
        absences: add(balanceA.absences, balanceB.absences),
        adjustments: add(balanceA.adjustments, balanceB.adjustments),
        balance: add(balanceA.balance, balanceB.balance),
    };
}

export function addVacationBalances<T extends Omit<VacationBalance, "employeeId" | "from" | "to">>(
    balanceA: T,
    balanceB: T
): T {
    return {
        ...balanceA,
        carry: add(balanceA.carry, balanceB.carry),
        actual: add(balanceA.actual, balanceB.actual),
        nominal: add(balanceA.nominal, balanceB.nominal),
        difference: add(balanceA.difference, balanceB.difference),
        taken: add(balanceA.taken, balanceB.taken),
        adjustments: add(balanceA.adjustments, balanceB.adjustments),
        balance: add(balanceA.balance, balanceB.balance),
    };
}

export function addBonusesBalances<T extends Omit<BonusesBalance, "employeeId" | "from" | "to">>(
    balanceA: T,
    balanceB: T
): T {
    return {
        ...balanceA,
        carry:
            typeof balanceA.carry === "number" && typeof balanceB.carry === "number"
                ? add(balanceA.carry, balanceB.carry)
                : (balanceA.carry ?? balanceB.carry),
        actual: add(balanceA.actual, balanceB.actual),
        nominal:
            typeof balanceA.nominal === "number" && typeof balanceB.nominal === "number"
                ? add(balanceA.nominal, balanceB.nominal)
                : (balanceA.nominal ?? balanceB.nominal),
        difference:
            typeof balanceA.difference === "number" && typeof balanceB.difference === "number"
                ? add(balanceA.difference, balanceB.difference)
                : (balanceA.difference ?? balanceB.difference),
        taxed: add(balanceA.taxed, balanceB.taxed),
        untaxed: add(balanceA.untaxed, balanceB.untaxed),
        balance:
            typeof balanceA.balance === "number" && typeof balanceB.balance === "number"
                ? add(balanceA.balance, balanceB.balance)
                : (balanceA.balance ?? balanceB.balance),
    };
}

/** @deprecated Use `addTimeBalance`, `addVacationBalance`, `addBonusesBalance` instead */
export function addBalances(balanceA: Balances, balanceB: Balances): Balances {
    return {
        ...balanceA,
        time: addTimeBalances(balanceA.time as TimeBalance, balanceB.time as TimeBalance),
        vacation: addVacationBalances(balanceA.vacation as VacationBalance, balanceB.vacation as VacationBalance),
        bonuses: addBonusesBalances(balanceA.bonuses as BonusesBalance, balanceB.bonuses as BonusesBalance),
    };
}

export function makeEmptyTimeBalance({ employeeId, from, to }: DateRange & { employeeId: number }): TimeBalance {
    return {
        employeeId,
        from,
        to,
        carry: 0 as Hours,
        actual: 0 as Hours,
        nominal: 0 as Hours,
        difference: 0 as Hours,
        work: 0 as Hours,
        absences: 0 as Hours,
        adjustments: 0 as Hours,
        balance: 0 as Hours,
    };
}

export function makeEmptyVacationBalance({
    employeeId,
    from,
    to,
}: DateRange & { employeeId: number }): VacationBalance {
    return {
        employeeId,
        from,
        to,
        carry: 0 as Days,
        actual: 0 as Days,
        nominal: 0 as Days,
        difference: 0 as Days,
        taken: 0 as Days,
        adjustments: 0 as Days,
        balance: 0 as Days,
    };
}

export function makeEmptyBonusesBalance({ employeeId, from, to }: DateRange & { employeeId: number }): BonusesBalance {
    return {
        employeeId,
        from,
        to,
        actual: 0 as Euros,
        taxed: 0 as Euros,
        untaxed: 0 as Euros,
    };
}

/** @deprecated Use `makeEmptyTimeBalance`, `makeEmptyVacationBalance`, `makeEmptyBonusesBalance` instead */
export function makeEmptyBalances({ employeeId, from, to }: DateRange & { employeeId: number }): Balances {
    return {
        employeeId,
        from,
        to,
        time: makeEmptyTimeBalance({ employeeId, from, to }),
        vacation: makeEmptyVacationBalance({ employeeId, from, to }),
        bonuses: makeEmptyBonusesBalance({ employeeId, from, to }),
    };
}

export function sumTimeBalances(balances: TimeBalance[]) {
    if (!balances.length) {
        return makeEmptyTimeBalance({
            employeeId: 0,
            from: "1970-01-01" as DateString,
            to: "1970-01-01" as DateString,
        });
    }
    return balances.reduce<TimeBalance>(
        (total, each) => addTimeBalances(total, each),
        makeEmptyTimeBalance(balances[0])
    );
}

export function sumVacationBalances(balances: VacationBalance[]) {
    if (!balances.length) {
        return makeEmptyVacationBalance({
            employeeId: 0,
            from: "1970-01-01" as DateString,
            to: "1970-01-01" as DateString,
        });
    }
    return balances.reduce<VacationBalance>(
        (total, each) => addVacationBalances(total, each),
        makeEmptyVacationBalance(balances[0])
    );
}

export function sumBonusesBalances(balances: BonusesBalance[]) {
    if (!balances.length) {
        return makeEmptyBonusesBalance({
            employeeId: 0,
            from: "1970-01-01" as DateString,
            to: "1970-01-01" as DateString,
        });
    }
    return balances.reduce<BonusesBalance>(
        (total, each) => addBonusesBalances(total, each),
        makeEmptyBonusesBalance(balances[0])
    );
}

/** @deprecated Use `sumTimeBalance`, `sumVacationBalance`, `sumBonusesBalance` instead */
export function sumBalances(balances: Balances[]) {
    if (!balances.length) {
        return makeEmptyBalances({ employeeId: 0, from: "1970-01-01" as DateString, to: "1970-01-01" as DateString });
    }
    return balances.reduce<Balances>((total, each) => addBalances(total, each), makeEmptyBalances(balances[0]));
}

/** @deprecated Use `timeBalanceFromDailyResults`, `vacationBalanceFromDailyResults`, `bonusesBalanceFromDailyResults` instead */
export function aggregateDailyResults(
    results: DailyResults[],
    roundValues = true,
    projected = false
): Omit<Balances, "employeeId" | "from" | "to"> {
    const firstResult = results[0];
    const today = toDateString(new Date());
    const firstTimeBalance =
        (projected && firstResult?.date >= today && firstResult?.timeBalancePlanned) || firstResult?.timeBalance;

    const balances = results.reduce(
        (total, result) => {
            const timeBalance = (projected && result.date >= today && result.timeBalancePlanned) || result.timeBalance;
            return {
                ...total,
                time: {
                    ...total.time,
                    carry: total.time.carry ?? timeBalance.carry,
                    actual: add(total.time.actual, timeBalance.actual),
                    nominal: add(total.time.nominal, timeBalance.nominal),
                    difference: add(total.time.difference, timeBalance.difference),
                    work: add(total.time.work, timeBalance.work),
                    absences: add(total.time.absences, timeBalance.absences),
                    adjustments: add(total.time.adjustments, timeBalance.adjustments),
                    balance: timeBalance.balance ?? total.time.balance,
                },
                vacation: {
                    ...total.vacation,
                    carry: total.vacation.carry ?? result.vacationBalance.carry,
                    taken: add(total.vacation.taken, result.vacationBalance.taken),
                    adjustments: add(total.vacation.adjustments, result.vacationBalance.adjustments),
                    actual: add(total.vacation.actual, result.vacationBalance.actual),
                    nominal: add(total.vacation.nominal, result.vacationBalance.nominal),
                    difference: add(total.vacation.difference, result.vacationBalance.difference),
                    balance: result.vacationBalance.balance ?? total.vacation.balance,
                },
                bonuses: {
                    ...total.bonuses,
                    carry: total.bonuses.carry ?? result.bonusesBalance.carry,
                    taxed: add(total.bonuses.taxed, result.bonusesBalance.taxed),
                    untaxed: add(total.bonuses.untaxed, result.bonusesBalance.untaxed),
                    actual: add(total.bonuses.actual, result.bonusesBalance.actual),
                    nominal:
                        typeof total.bonuses.nominal === "number" && typeof result.bonusesBalance.nominal === "number"
                            ? add(total.bonuses.nominal, result.bonusesBalance.nominal)
                            : (total.bonuses.nominal ?? result.bonusesBalance.nominal),
                    difference:
                        typeof total.bonuses.difference === "number" &&
                        typeof result.bonusesBalance.difference === "number"
                            ? add(total.bonuses.difference, result.bonusesBalance.difference)
                            : (total.bonuses.difference ?? result.bonusesBalance.difference),
                    balance: result.bonusesBalance.balance ?? total.bonuses.balance,
                },
            };
        },
        {
            time: {
                carry: firstTimeBalance?.carry || (0 as Hours),
                work: 0 as Hours,
                absences: 0 as Hours,
                adjustments: 0 as Hours,
                actual: 0 as Hours,
                nominal: 0 as Hours,
                difference: 0 as Hours,
                balance: firstTimeBalance?.balance || (0 as Hours),
                reset: firstTimeBalance?.reset,
            },
            vacation: {
                carry: firstResult?.vacationBalance.carry || (0 as Days),
                adjustments: 0 as Days,
                taken: 0 as Days,
                actual: 0 as Days,
                nominal: 0 as Days,
                difference: 0 as Days,
                balance: firstResult?.vacationBalance.balance || (0 as Days),
                reset: firstResult?.vacationBalance.reset,
            },
            bonuses: {
                carry: firstResult?.bonusesBalance.carry,
                taxed: 0 as Euros,
                untaxed: 0 as Euros,
                actual: 0 as Euros,
                balance: firstResult?.bonusesBalance.balance,
                reset: firstResult?.bonusesBalance.reset,
            },
        } as Omit<Balances, "employeeId" | "from" | "to">
    );

    return roundValues ? roundBalances(balances) : balances;
}

export function aggregateTimeBalances(
    employee: Employee,
    { from, to }: DateRange,
    results: DailyResults[],
    roundValues = true,
    projected = false
): TimeBalance {
    const firstResult = results[0];
    const lastResult = results[results.length - 1];
    const today = toDateString(new Date());
    const firstBalance =
        (projected && firstResult?.date >= today && firstResult?.timeBalancePlanned) || firstResult?.timeBalance;
    const lastBalance =
        (projected && lastResult?.date >= today && lastResult?.timeBalancePlanned) || lastResult?.timeBalance;

    const balance = results.reduce(
        (total, result) => {
            const balance = (projected && result.date >= today && result.timeBalancePlanned) || result.timeBalance;
            return {
                ...addTimeBalances(total, balance),
                employeeId: employee.id,
                from,
                to,
                reset: total.reset,
                carry: total.carry,
                balance: total.balance,
            };
        },
        {
            ...makeEmptyTimeBalance({ employeeId: employee.id, from, to }),
            reset: firstBalance?.reset,
            carry: firstBalance?.carry || (0 as Hours),
            balance: lastBalance?.balance || (0 as Hours),
        } as TimeBalance
    );

    return roundValues ? roundTimeBalance(balance) : balance;
}

export function aggregateVacationBalances(
    employee: Employee,
    { from, to }: DateRange,
    results: DailyResults[],
    roundValues = true
): VacationBalance {
    const firstResult = results[0];
    const lastResult = results[results.length - 1];

    const balance = results.reduce(
        (total, result) => {
            return {
                ...addVacationBalances(total, result.vacationBalance),
                employeeId: employee.id,
                from,
                to,
                reset: total.reset,
                carry: total.carry,
                balance: total.balance,
            };
        },
        {
            ...makeEmptyVacationBalance({ employeeId: employee.id, from, to }),
            reset: firstResult?.vacationBalance.reset,
            carry: firstResult?.vacationBalance.carry || (0 as Hours),
            balance: lastResult?.vacationBalance.balance || (0 as Hours),
        } as VacationBalance
    );

    return roundValues ? roundVacationBalance(balance) : balance;
}

export function aggregateBonusesBalances(
    employee: Employee,
    { from, to }: DateRange,
    results: DailyResults[],
    roundValues = true
): BonusesBalance {
    const firstResult = results[0];
    const lastResult = results[results.length - 1];

    const balance = results.reduce(
        (total, result) => {
            return {
                ...addBonusesBalances(total, result.bonusesBalance),
                employeeId: employee.id,
                from,
                to,
                reset: total.reset,
                carry: total.carry,
                balance: total.balance,
            };
        },
        {
            ...makeEmptyBonusesBalance({ employeeId: employee.id, from, to }),
            reset: firstResult?.bonusesBalance.reset,
            carry: firstResult?.bonusesBalance.carry || (0 as Hours),
            balance: lastResult?.bonusesBalance.balance || (0 as Hours),
        } as BonusesBalance
    );

    return roundValues ? roundBonusesBalance(balance) : balance;
}

function getChunks({ from, to }: DateRange, resets: DailyResults[]): DateRange[] {
    const chunks = [];
    let chunkFrom = from;
    for (const reset of resets) {
        chunks.push({
            from: chunkFrom,
            to: reset.date,
        });
        chunkFrom = reset.date;
    }
    chunks.push({
        from: chunkFrom,
        to,
    });
    return chunks;
}

export function timeBalanceFromDailyResults(
    employee: Employee,
    range: DateRange,
    results: DailyResults[],
    roundValues = true,
    projected = false
): TimeBalance {
    const balance = aggregateTimeBalances(employee, range, results, roundValues, projected);
    const resets = results.filter((res, i) => i !== 0 && typeof res.timeBalance.reset !== "undefined");

    if (resets.length) {
        const chunks = getChunks(range, resets);

        balance.subBalances = [];

        for (const subRange of chunks) {
            balance.subBalances.push(
                aggregateTimeBalances(
                    employee,
                    subRange,
                    results.filter((res) => res.date >= subRange.from && res.date < subRange.to),
                    true,
                    projected
                )
            );
        }
    }

    return balance;
}

export function vacationBalanceFromDailyResults(
    employee: Employee,
    range: DateRange,
    results: DailyResults[],
    roundValues = true
): VacationBalance {
    const balance = aggregateVacationBalances(employee, range, results, roundValues);
    const resets = results.filter((res, i) => i !== 0 && typeof res.vacationBalance.reset !== "undefined");

    if (resets.length) {
        const chunks = getChunks(range, resets);

        balance.subBalances = [];

        for (const subRange of chunks) {
            balance.subBalances.push(
                aggregateVacationBalances(
                    employee,
                    subRange,
                    results.filter((res) => res.date >= subRange.from && res.date < subRange.to),
                    true
                )
            );
        }
    }

    return balance;
}

export function bonusesBalanceFromDailyResults(
    employee: Employee,
    range: DateRange,
    results: DailyResults[],
    roundValues = true
): BonusesBalance {
    const balance = aggregateBonusesBalances(employee, range, results, roundValues);
    const resets = results.filter((res, i) => i !== 0 && typeof res.bonusesBalance.reset !== "undefined");

    if (resets.length) {
        const chunks = getChunks(range, resets);

        balance.subBalances = [];

        for (const subRange of chunks) {
            balance.subBalances.push(
                aggregateBonusesBalances(
                    employee,
                    subRange,
                    results.filter((res) => res.date >= subRange.from && res.date < subRange.to),
                    true
                )
            );
        }
    }

    return balance;
}

export type DateRange = {
    from: DateString;
    /**
     * Parameter `to` is exclusive, meaning that the range will end at the day before the given date,
     * e.g. `to: '2024-08-01'` will be used to calculate until `2024-07-31` but will ignore the date `2024-08-01`
     */
    to: DateString;
};

export type TimeResultsBreakdown = {
    items: {
        type: TimeEntryType;
        result: TimeResult;
        workArea: {
            position: Position;
            department: Department;
            venue: Venue;
        } | null;
        comment?: string;
    }[];
    total: TimeResult;
};

export function getResultBreakDown(company: Company, timeEntries: TimeEntry[]) {
    timeEntries = timeEntries.filter((e) => e.type !== TimeEntryType.VacationAdjustment && Boolean(e.result));

    const breakdown: TimeResultsBreakdown = {
        items: [],
        total: getTotalResults(timeEntries.filter((e) => Boolean(e.result)).map((entry) => entry.result!)),
    };

    const items = new Map<string, TimeResultsBreakdown["items"][number]>();

    for (const { type, positionId, result, comment } of timeEntries) {
        if (!result) {
            continue;
        }
        const key = [TimeEntryType.HourAdjustment, TimeEntryType.VacationAdjustment].includes(type)
            ? `${type}_${positionId}_${comment}`
            : `${type}_${positionId}`;
        const existing = items.get(key);

        if (existing) {
            existing.result = addTimeResults(existing.result, result);
        } else {
            const workArea = (positionId && company.getPosition(positionId)) || null;
            items.set(key, {
                type,
                result,
                workArea,
                comment: [TimeEntryType.HourAdjustment, TimeEntryType.VacationAdjustment].includes(type)
                    ? comment
                    : undefined,
            });
        }
    }

    breakdown.items = [...items.values()];

    return breakdown;
}

export function getAvailableActions(
    timeEntry: PentacodeAPIModels["TimeEntry"],
    settings: PentacodeAPIModels["TimeLogSettings"]
): PentacodeAPIModels["TimeLogAction"][] {
    const startString = timeEntry.startFinal || timeEntry.startPlanned;

    if (!startString) {
        return [];
    }

    const startTime = new Date(startString).getTime();

    const canStart = startTime - settings.allowEarlyStart * hour < Date.now();
    const isUnlocked = Date.now() < startTime + settings.lockAfter * hour;
    const shiftReady = canStart && isUnlocked;

    // Can't do anything if shift is not ready
    if (!shiftReady) return [];

    // If paused, can end the break or the shift
    if (timeEntry.status === "paused") {
        return ["endBreak", "endShift"];
    }

    // If ongoing, can end the shift or start a break if allowed
    if (timeEntry.status === "ongoing") {
        if (settings.manualBreaksAllowed) {
            return ["startBreak", "endShift"];
        }
        return ["endShift"];
    }

    // If scheduled, can start the shift
    if (timeEntry.status === "scheduled") {
        return ["startShift"];
    }

    // Default (e.g completed), can't do anything
    return [];
}

export function getVacationEntitlement(balance?: VacationBalance): {
    available: Days;
    taken: Days;
    remaining: Days;
} {
    if (!balance) {
        return {
            available: 0 as Days,
            taken: 0 as Days,
            remaining: 0 as Days,
        };
    }

    const lastBalance = balance.subBalances?.length ? balance.subBalances[balance.subBalances.length - 1] : balance;
    const available = add(lastBalance.reset ?? lastBalance.carry, lastBalance.nominal);
    const taken = lastBalance.actual;

    return {
        available,
        taken,
        remaining: subtract(available, taken),
    };
}

export function getCommonWorkDays(dailyResults: DailyResults[]) {
    const daysPerWeekDay = [0, 0, 0, 0, 0, 0, 0];
    const daysPerWeek = new Map<string, number>();
    for (const dailyResult of dailyResults) {
        const isWorkOrAbsenceDay =
            dailyResult.results.work.base.duration > 0 || dailyResult.results.absences.base.duration > 0;
        const weekDay = new Date(dailyResult.date).getDay();
        if (isWorkOrAbsenceDay) {
            daysPerWeekDay[weekDay]++;
            const week = `${parseDateString(dailyResult.date)!.getFullYear()},${getWeekNumber(dailyResult.date)}`;
            daysPerWeek.set(week, (daysPerWeek.get(week) || 0) + 1);
        }
    }
    const daysPerWeekArr = Array.from(daysPerWeek.values());
    const totaldays = daysPerWeekArr.reduce((a, b) => a + b, 0);
    const weeksWorked = daysPerWeekArr.filter((val) => val > 0).length;
    const averageDaysPerWeek = Math.round(totaldays / weeksWorked);
    const commonWorkDays = daysPerWeekDay
        .map((count, i) => [count, i])
        .sort(([a], [b]) => b - a)
        .slice(0, averageDaysPerWeek)
        .map(([, i]) => i);

    return commonWorkDays;
}
