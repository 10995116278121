import { Employee, TimeEntry, TimeEntryType, Company, BreakMode, EmploymentType, absenceTypes } from "./model";
import { dateAdd } from "./util";
import { DateRange, getAutomaticBreak, getStatutoryBreak, hour, TimeResult } from "./time";
import { add, DateString, Euros, getRate, Hours, multiplyWithRate, Rate, round, sum } from "@pentacode/openapi";
import { getMinimumHourlyRate } from "./salary";
import { getProratedPaymentForRange } from "./payroll";

export enum IssueType {
    /** Two shifts exists on the same day / employee with overlapping times (either planned or completed) */
    OverlappingShifts,
    /** The employee has exceeded the legally allowed daily work time (10 hours by default) */
    ExcessiveWorkDay,
    /** There is an insufficent time gap between the last shift of one day and the first shift of the next (legal minimum time gap is 11 hours) */
    DeficientIdlePeriod,
    /** The recorded break duration is lower than the legal minimum based on the shift duration */
    DeficientBreak,
    /**
     * The employees effective hourly rate in the given payroll period
     * (([salary] + [other wages]) / [worked hours]) is below the legal threshold
     */
    DeficientWages,
    /** The employe has exceeded their legal wage limit */
    ExcessiveWages,
    /** No actual start time has been recorded for a planned shift even after the planned time has passed */
    MissingShiftStart,
    /** No actual end time has been recorded for a planned shift even after the planned time has passed */
    MissingShiftEnd,
    /** No contract exists for the shift date */
    MissingContract,
    /** A daily revenue report has not been completed */
    PendingDailyRevenues,
    /** The daily revenue report has conflicting values for the expected / actual cash balance */
    UnbalancedDailyCash,
}

/**
 * These are issue types related to employees (shift- and payroll-related issues)
 */
export const EmployeeIssueTypes = [
    IssueType.OverlappingShifts,
    IssueType.ExcessiveWorkDay,
    IssueType.DeficientIdlePeriod,
    IssueType.DeficientBreak,
    IssueType.DeficientWages,
    IssueType.ExcessiveWages,
    IssueType.MissingShiftStart,
    IssueType.MissingShiftEnd,
    IssueType.MissingContract,
];

/**
 * These are issue types related to cashbook (daily revenue) reports
 */
export const CashbookIssueTypes = [IssueType.PendingDailyRevenues, IssueType.UnbalancedDailyCash];

export function getIssueMessage(type: IssueType) {
    switch (type) {
        case IssueType.OverlappingShifts:
            return "Schichtüberlappung";
        case IssueType.ExcessiveWorkDay:
            return "Arbeitszeit > 10 Std.";
        case IssueType.DeficientIdlePeriod:
            return "Ruhezeit < 11 Std.";
        case IssueType.DeficientBreak:
            return "Unterschreitung ges. Pause";
        case IssueType.DeficientWages:
            return "Mindestlohnunterschr.";
        case IssueType.ExcessiveWages:
            return "Überschr. Lohngrenze";
        case IssueType.MissingShiftStart:
            return "Schicht Nicht Angetr.";
        case IssueType.MissingShiftEnd:
            return "Schicht Nicht Beendet";
        case IssueType.MissingContract:
            return "Außerh. Vertragszeitr.";
        case IssueType.PendingDailyRevenues:
            return "Tagesabrechnung nicht abgeschlossen";
        case IssueType.UnbalancedDailyCash:
            return "Fehlerhafte Tagesabrechnung";
    }
}

export type IssueShiftDetails = {
    id: string;
    type: TimeEntryType;
    date: DateString;
    start: string | null;
    end: string | null;
    duration: Hours;
    break: Hours;
    employeeId: number;
    positionId: number | null;
};

type IssueDetailsMap = {
    [IssueType.OverlappingShifts]: {
        employeeId: number;
        shifts: IssueShiftDetails[];
    };
    [IssueType.ExcessiveWorkDay]: {
        employeeId: number;
        shifts: IssueShiftDetails[];
        totalDuration: Hours;
        maxDuration: Hours;
    };
    [IssueType.DeficientIdlePeriod]: {
        employeeId: number;
        shifts: IssueShiftDetails[];
        idleDuration: Hours;
        minIdleDuration: Hours;
    };
    [IssueType.MissingShiftStart]: {
        employeeId: number;
        shift: IssueShiftDetails;
    };
    [IssueType.MissingShiftEnd]: {
        employeeId: number;
        shift: IssueShiftDetails;
    };
    [IssueType.MissingContract]: {
        employeeId: number;
        shift: IssueShiftDetails;
    };
    [IssueType.DeficientBreak]: {
        employeeId: number;
        shift: IssueShiftDetails;
        minBreak: Hours;
    };
    [IssueType.DeficientWages]: {
        employeeId: number;
        hours: Hours;
        wages: Euros;
        effectiveHourlyRate: Rate<Euros, Hours>;
        minHourlyRate: Rate<Euros, Hours>;
    };
    [IssueType.ExcessiveWages]: {
        employeeId: number;
        maxWages: Euros;
        wages: Euros;
    };
    [IssueType.PendingDailyRevenues]: {
        venueId: number;
    };
    [IssueType.UnbalancedDailyCash]: {
        venueId: number;
        expected: Euros;
        actual: Euros;
    };
};

export type IssueDetails<T extends IssueType = IssueType> = T extends keyof IssueDetailsMap
    ? IssueDetailsMap[T]
    : undefined;

export type Issue = {
    [K in IssueType]: {
        type: K;
        date: DateString;
        details: IssueDetails<K>;
        ignored: boolean;
    };
}[IssueType];

function timeEntryToShiftDetails(entry: TimeEntry) {
    return {
        id: entry.id,
        type: entry.type,
        date: entry.date,
        start: entry.startFinal?.toISOString() || entry.startPlanned?.toISOString() || null,
        end: entry.endFinal?.toISOString() || entry.endPlanned?.toISOString() || null,
        duration: entry.duration,
        break: entry.break || (0 as Hours),
        employeeId: entry.employeeId!,
        positionId: entry.positionId,
    };
}

/**
 * Get all issues related to shifts. Includes:
 *
 *   - IssueType.OverlappingShifts,
 *   - IssueType.ExcessiveWorkDay,
 *   - IssueType.DeficientIdlePeriod,
 *   - IssueType.DeficientBreak,
 *   - IssueType.MissingShiftStart,
 *   - IssueType.MissingShiftEnd,
 *   - IssueType.MissingContract,
 */
export function getShiftIssues(
    employee: Employee,
    company: Company,
    timeEntries: TimeEntry[],
    { from, to }: DateRange
): Issue[] {
    const issues: Issue[] = [];
    const now = new Date();
    const commitBefore = company.settings.commitTimeEntriesBefore;
    if (commitBefore && commitBefore > from) {
        from = commitBefore;
    }
    const fromIncludingPreviousDay = dateAdd(from, { days: -1 });

    timeEntries = timeEntries
        .filter(
            (entry) =>
                !entry.deleted &&
                entry.employeeId === employee.id &&
                entry.type === TimeEntryType.Work &&
                // Don't include "open" planned shifts
                (entry.planned || entry.final) &&
                // Include time entries from the last day before the current period to check for shift overlaps
                entry.date >= fromIncludingPreviousDay &&
                entry.date < to
        )
        .sort((a, b) => Number(a.start) - Number(b.start));

    for (let i = 0, dayEntries: TimeEntry[] = []; i < timeEntries.length; i++) {
        const curr = timeEntries[i];
        const next = timeEntries[i + 1];

        dayEntries.push(curr);

        // Last shift of the day
        if (!next || next.start.toDateString() !== curr.start.toDateString()) {
            // Check if total work duration on this day exceeds 10 hours
            const totalDur = dayEntries.reduce((total, timeEntry) => {
                const timeSettings = company.getTimeSettings({ timeEntry });
                const brk = timeEntry.final
                    ? timeEntry.break || 0
                    : [BreakMode.Planned, BreakMode.PlannedPlusManual].includes(timeSettings.breakMode)
                      ? timeEntry.breakPlanned || 0
                      : getAutomaticBreak(timeEntry.duration, timeSettings);
                return total + (timeEntry.end.getTime() - timeEntry.start.getTime()) - brk * hour;
            }, 0);

            // Employees may not work more than 10 hours a day
            if (totalDur > 10 * hour) {
                issues.push({
                    type: IssueType.ExcessiveWorkDay,
                    date: dayEntries[0].date,
                    details: {
                        employeeId: curr.employeeId!,
                        shifts: dayEntries.map((entry) => timeEntryToShiftDetails(entry)),
                        totalDuration: totalDur as Hours,
                        maxDuration: (10 * hour) as Hours,
                    },
                    ignored: dayEntries.some((e) => e.ignoreIssues?.includes(IssueType.ExcessiveWorkDay)),
                });
            }

            // Check if there is at least 11 hours between the last shift of the day and the first shift of the next
            const idleDuration = next && next.start.getTime() - curr.end.getTime();
            if (idleDuration && idleDuration < 11 * hour) {
                issues.push({
                    type: IssueType.DeficientIdlePeriod,
                    date: next.date,
                    details: {
                        employeeId: curr.employeeId!,
                        shifts: [timeEntryToShiftDetails(curr), timeEntryToShiftDetails(next)],
                        idleDuration: idleDuration as Hours,
                        minIdleDuration: (11 * hour) as Hours,
                    },
                    ignored: [curr, next].some((e) => e.ignoreIssues?.includes(IssueType.DeficientIdlePeriod)),
                });
            }

            dayEntries = [];
        }

        // Check if the planned shift start / end is in the past but no actual start time has been recorded
        if (now > curr.end && (!curr.startFinal || !curr.endFinal)) {
            const type = !curr.startFinal ? IssueType.MissingShiftStart : IssueType.MissingShiftEnd;
            issues.push({
                type,
                date: curr.date,
                details: {
                    employeeId: curr.employeeId!,
                    shift: timeEntryToShiftDetails(curr),
                },
                ignored: curr.ignoreIssues?.includes(type),
            });
        }

        // Check for overlapping shifts on the same day
        if (next && curr.end! > next.start!) {
            issues.push({
                type: IssueType.OverlappingShifts,
                date: next.date,
                details: {
                    employeeId: curr.employeeId!,
                    shifts: [timeEntryToShiftDetails(curr), timeEntryToShiftDetails(next)],
                },
                ignored: [curr, next].some((e) => e.ignoreIssues?.includes(IssueType.OverlappingShifts)),
            });
        }

        // Check if the recorded break duration is lower than the legal minimum based on the shift duration
        const statutoryBreak = getStatutoryBreak(curr.duration, company.settings);
        if (curr.final && (curr.break || 0) < statutoryBreak) {
            issues.push({
                type: IssueType.DeficientBreak,
                date: curr.date,
                details: {
                    employeeId: curr.employeeId!,
                    shift: timeEntryToShiftDetails(curr),
                    minBreak: statutoryBreak,
                },
                ignored: curr.ignoreIssues?.includes(IssueType.DeficientBreak),
            });
        }

        // Check if a contract exists for the shift date
        if (!employee.getContractForDate(curr.date)) {
            issues.push({
                date: curr.date,
                type: IssueType.MissingContract,
                details: {
                    shift: timeEntryToShiftDetails(curr),
                    employeeId: curr.employeeId!,
                },
                ignored: curr.ignoreIssues?.includes(IssueType.MissingContract),
            });
        }
    }

    return issues.filter((issue) => issue.date >= from);
}

/**
 *  Extracts the shift details from an issue for those that have any
 */
export function getShiftsForIssue(issue: Issue) {
    if ("shifts" in issue.details) {
        return issue.details.shifts;
    } else if ("shift" in issue.details) {
        return [issue.details.shift];
    } else {
        return [];
    }
}

/**
 * Get all payroll-related issues this includes
 *
 *   - IssueType.ExcessiveWages,
 *   - IssueType.DeficientWages,
 */
export function getPayrollIssues(
    company: Company,
    employee: Employee,
    timeEntries: TimeEntry[],
    { from, to }: DateRange
): Issue[] {
    const issues: Issue[] = [];

    const deficientWagesIssue = checkDeficientWages(company, employee, timeEntries, { from, to });
    const excessiveWagesIssue = checkExcessiveWages(company, employee, timeEntries, { from, to });

    if (deficientWagesIssue) {
        issues.push(deficientWagesIssue);
    }

    if (excessiveWagesIssue) {
        issues.push(excessiveWagesIssue);
    }

    return issues;
}

/**
 * Calculates the total pay applicable to determining whether an employee has received the minimum
 * effective hourly rate or exceeded the maximum wage limit. No all wage types are included in this.
 * Included are:
 *
 * - Salaries
 * - Benefits, if `includeInMinimumWageComparison = true`
 * - Hourly wages (including absences and paid breaks)
 * - Taxed bonuses (bonuses from absences or those exceeding the legal limit (25€/hour))
 * - Commissions
 *
 * **Not** included are:
 *
 * - Taxfree bonuses
 * - Time/vacation bookings
 * - Advance pay
 */
function getPayRelevantToPayrollIssues(
    company: Company,
    employee: Employee,
    timeEntries: TimeEntry[],
    { from, to }: DateRange
) {
    // Only include time entries in the given time entries that have a result
    const relevantTimeEntries = timeEntries.filter(
        (timeEntry) =>
            [TimeEntryType.Work, ...absenceTypes].includes(timeEntry.type) &&
            timeEntry.date >= from &&
            timeEntry.date < to &&
            timeEntry.result
    ) as (TimeEntry & { result: TimeResult })[];

    const contracts = employee.getAllContractsForRange({ from, to });

    const benefits = contracts
        .flatMap((c) => {
            return c.benefits.flatMap((benefit) => {
                return getProratedPaymentForRange(c, { from, to }, benefit, company.settings.startPayrollPeriod).map(
                    (interval) => ({
                        amount: interval.amount,
                        benefit,
                    })
                );
            });
        })
        // Only include beneftis where `includeInMinimumWageComparison = true`
        .filter(({ benefit }) => {
            const benefitType = company.benefitTypes.find((bt) => bt.id === benefit.typeId);
            return benefitType?.includeInMinimumWageComparison;
        })
        .reduce((sum, benefit) => add(sum, benefit.amount), 0 as Euros);

    const salaries = contracts
        .flatMap((c) => {
            return c.salaries
                .filter((s) => s.type === "monthly")
                .flatMap((salary) => {
                    return getProratedPaymentForRange(c, { from, to }, salary, company.settings.startPayrollPeriod).map(
                        (interval) => ({
                            amount: interval.amount,
                            salary,
                        })
                    );
                });
        })
        .reduce((sum, salary) => add(sum, salary.amount), 0 as Euros);

    const hourlyWages = relevantTimeEntries
        .filter((timeEntry) => employee.getContractForDate(timeEntry.date)?.getSalary(timeEntry)?.type === "hourly")
        .reduce(
            (total, timeEntry) =>
                add(
                    total,
                    multiplyWithRate(
                        round(add(timeEntry.result.base.duration, timeEntry.result.breaks.paidDuration), 2),
                        timeEntry.result.base.hourlyRate
                    )
                ),
            0 as Euros
        );

    const taxedBonuses = relevantTimeEntries.reduce(
        (total, r) =>
            sum(
                total,
                r.result.bonuses.filter((b) => !b.taxFree).reduce((sum, b) => add(sum, b.wages), 0 as Euros)
            ),
        0 as Euros
    );

    const commissions = relevantTimeEntries.reduce(
        (total, r) => sum(total, r.result.commission?.wages || (0 as Euros)),
        0 as Euros
    );

    return sum(salaries, benefits, hourlyWages, taxedBonuses, commissions);
}

/** Checks whether the employee has received the legal minimum effective hourly rate */
function checkDeficientWages(
    company: Company,
    employee: Employee,
    timeEntries: TimeEntry[],
    { from, to }: DateRange
): Issue | null {
    const date = dateAdd(to, { days: -1 });
    const contracts = employee.getAllContractsForRange({ from, to });
    const contractAtPeriodEnd = employee.getContractForDate(date);

    // Inters and Trainees are not subject to minimum wage laws.
    // Also if an annual time sheet is kept for the employee, we don't need to check for minimum wage
    const checkMinimumWage = contracts.some(
        (c) =>
            c.employmentType !== EmploymentType.Intern &&
            c.employmentType !== EmploymentType.Trainee &&
            !c.annualTimeSheet
    );

    if (!contractAtPeriodEnd || !checkMinimumWage) {
        return null;
    }

    const wages = getPayRelevantToPayrollIssues(company, employee, timeEntries, { from, to });

    const hours = timeEntries
        .filter(
            (timeEntry) =>
                [TimeEntryType.Work, ...absenceTypes].includes(timeEntry.type) &&
                timeEntry.date >= from &&
                timeEntry.date < to
        )
        .reduce(
            (total, timeEntry) =>
                sum(total, timeEntry.result?.base.duration || 0, timeEntry.result?.breaks.paidDuration || 0),
            0 as Hours
        );

    if (!wages || !hours) {
        return null;
    }

    const effectiveHourlyRate = round(getRate(round(wages, 2), round(hours, 2)), 2);
    const minHourlyRate = getMinimumHourlyRate(from) as Rate<Euros, Hours>;

    // We're giving a little bit of wiggle room here to account for rounding errors
    if (checkMinimumWage && effectiveHourlyRate - minHourlyRate < -0.01) {
        return {
            type: IssueType.DeficientWages,
            date,
            details: {
                employeeId: employee.id,
                hours,
                wages,
                minHourlyRate,
                effectiveHourlyRate,
            },
            ignored: contractAtPeriodEnd.ignoreIssues.some(
                (issue) => issue.type === IssueType.DeficientWages && issue.date === date
            ),
        };
    }

    return null;
}

/** Checks whether the employee has exceeded their wage limit (only applicable to marginal employees and "midi jobbers") */
function checkExcessiveWages(
    company: Company,
    employee: Employee,
    timeEntries: TimeEntry[],
    { from, to }: DateRange
): Issue | null {
    const date = dateAdd(to, { days: -1 });
    const contracts = employee.getAllContractsForRange({ from, to });
    const contractAtPeriodEnd = employee.getContractForDate(date);

    if (!contractAtPeriodEnd) {
        return null;
    }

    const maxWages = contracts.find(
        (c) => [EmploymentType.Marginal, EmploymentType.MidiJob].includes(c.employmentType) && c.maxSalary
    )?.maxSalary;

    if (!maxWages) {
        return null;
    }

    const wages = getPayRelevantToPayrollIssues(company, employee, timeEntries, { from, to });

    if (wages && maxWages && wages > maxWages) {
        return {
            type: IssueType.ExcessiveWages,
            date,
            details: {
                employeeId: employee.id,
                wages,
                maxWages,
            },
            ignored: contractAtPeriodEnd.ignoreIssues.some(
                (issue) => issue.type === IssueType.ExcessiveWages && issue.date === date
            ),
        };
    }

    return null;
}

export type EmployeeIssue = Exclude<
    Issue,
    { type: IssueType.PendingDailyRevenues } | { type: IssueType.UnbalancedDailyCash }
>;

export type CashbookIssue = Extract<
    Issue,
    { type: IssueType.PendingDailyRevenues } | { type: IssueType.UnbalancedDailyCash }
>;
