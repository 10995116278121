import { minute } from "@pentacode/core/src/time";
import { version, VersionInfo } from "@pentacode/core/src/version";
import { Workbox, messageSW } from "workbox-window";
import { getSingleton } from "../lib/singleton";
import type { UpdateNotification } from "../elements/update-notification";
import "../elements/update-notification";

type Constructor<T> = new (...args: any[]) => T;

export function ServiceWorker<B extends Constructor<object>>(baseClass: B) {
    return class extends baseClass {
        constructor(...args: any[]) {
            super(...args);
            void this._initSW();
        }

        private _wb: Workbox;

        private async _updateReady(info: VersionInfo) {
            // const confirmed = await (getSingleton("ptc-update-dialog") as UpdateDialog).show(info);

            // if (confirmed) {
            // set up a listener that will reload the page as soon as the
            // previously waiting service worker has taken control.
            this._wb.addEventListener("controlling", () => {
                void (getSingleton("ptc-update-notification") as UpdateNotification).show(info);
            });

            // Send a message telling the service worker to skip waiting.
            // This will trigger the `controlling` event handler above.
            void this._wb.messageSW({ type: "INSTALL_UPDATE" });
            // }
        }

        private async _initSW() {
            if (!("serviceWorker" in navigator)) {
                return;
            }

            this._wb = new Workbox("/sw.js");

            // Add an event listener to detect when the registered
            // service worker has installed but is waiting to activate.
            this._wb.addEventListener("waiting", async (e) => {
                if (!e.sw) {
                    return;
                }

                const info = (await messageSW(e.sw, { type: "GET_VERSION", currentVersion: version })) as VersionInfo;
                void this._updateReady(info);
            });

            await this._wb.register();

            this._pollForUpdates();
        }

        private async _checkForUpdates() {
            try {
                await this._wb.update();
            } catch (e) {
                console.error("Error checking for updates", e);
            }
        }

        private _pollForUpdates(interval = 10 * minute) {
            void this._checkForUpdates();
            setInterval(() => {
                void this._checkForUpdates();
            }, interval);
        }
    };
}
